import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {
    $(function() {
      $('.accordion').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');
      });
    });

    this.title.setTitle("FAQs - Forex Trading Bot | Trading Robot Software ");
    this.metaService.updateTag( { name:'description',content:"The most frequently asked questions are, How to Withdraw the Trading Amount? From a trading app to your account or from a wallet like skrill, Neteller etc., you can withdraw your amount."});
    this.metaService.updateTag( { name:'keywords',content:"faq, frequently asked questions, faq for forex trading, forex trading bot faq, robot trading software related questions, faq for auto trading software, forex trading software faq, trading software, auto trading robot, automated trading, forex software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
