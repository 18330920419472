import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Forex Trading Bot Features and Benefits");
    this.metaService.updateTag( { name:'description',content:"The most well-known forex trading bots are offered through the MetaTrader 4 stage. Our Forex Trading Bot are coming with money management features and risk management."});
    this.metaService.updateTag( { name:'keywords',content:"features, forex trading features, features of forex trading bot, features of robot trading software,  forex trading bot features, auto trading software, forex trading software, trading software, auto trading robot features, automated trading, forex software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
