import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Forex Trading Bot - Pricing Plans | Price Details ");
    this.metaService.updateTag( { name:'description',content:"Get the best pricing plan details for Forex Auto Trading account Setup; Forex bot Setup; Multiple Auto Trading account Setup etc., Our company provides two plans 1. Basic Plan and 2. Advanced Plan"});
    this.metaService.updateTag( { name:'keywords',content:"forex trading bot price, cost of forex trading bot, robot trading software cost, auto trading software price details, cost of forex trading software, pricing plans for trading software, cost of auto trading robot, automated trading, forex software price, cost of automatic trading  robot"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
