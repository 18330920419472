import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Assistance for Trading Software Installation");
    this.metaService.updateTag( { name:'description',content:"Convert all data into tickets. We focus on, order and appoint them to the right Department. We give 24x7 assistance for Trading Software Installation and any forex trading related enquiries."});
    this.metaService.updateTag( { name:'keywords',content:"robot trading support, forex trading support, forex trading bot assistance, trading software guidance, assistance for trading software installation, auto trading software guidance, forex trading software support, trading software installation guidance, auto trading robot support"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
