import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Forex Trading Bot - Terms and conditions");
    this.metaService.updateTag( { name:'description',content:"In our site, including all data, apparatuses and administrations accessible from this site to you, the client, is moulded upon your acknowledgement of all terms, conditions, strategies and notification expressed here."});
    this.metaService.updateTag( { name:'keywords',content:"terms and conditions, terms and conditions for forex robot, terms and conditions of forex trading, forex trading bot terms and conditions, terms of robot trading software, auto trading software terms and conditions, forex trading software, trading software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }
}
