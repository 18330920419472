import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Refund Policy for Forex Trading Bot | Trading Software");
    this.metaService.updateTag( { name:'description',content:"From The Time of Signing Up (subsequent to Submitting the Agreement) Maximum 48hrs to 72 working hrs. , If Service isn't Delivered For Configuring Your Bot, You can demand for Refund."});
    this.metaService.updateTag( { name:'keywords',content:"refund policy, refund terms, refund conditions for trading software, forex trading refund policy, refund policy for forex trading bot, robot trading software, auto trading software, refund policy for trading software, trading software refund policy"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
