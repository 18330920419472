import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Privacy and Policy - Forex Trading Bot | Robot Trading");
    this.metaService.updateTag( { name:'description',content:"Assuming that you have any various forms of feedback about our utilization of your own data, then kindly get in touch with us utilizing the contact subtleties given in the lower part of this Privacy Policy."});
    this.metaService.updateTag( { name:'keywords',content:"privacy policy, privacy statement, privacy terms, privacy and policy, trading privacy and policies, trading software privacy terms, forex robot trading policy, robot trading software, auto robot trading, auto trading software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
